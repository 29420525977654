import '../sass/main.scss';

import LazyLoad from 'vanilla-lazyload';

var siteGlobal = {
  initReady: function () {
  	this.countDownInit();  
  },
  initLoad: function () {
    this.lazyLoad();   
    // this.scrollHue();
  },
  initScroll: function(){
  },
  lazyLoad: function(){
    var ll = new LazyLoad({
        elements_selector: '.lazy',
        use_native: true,
        threshold: 0
    })
  },
  scrollHue: function(){
    $(window).on('scroll', function(){
      var scroll = document.documentElement.scrollTop;
      var scrollDef = scroll / 3.5;
      $('.tree-container').css('filter','hue-rotate('+scrollDef+'deg)');
    })
  },
  countDownInit: function(){
    var timeleft = 10;
    var downloadTimer = setInterval(function(){
      if(timeleft <= 0){
        clearInterval(downloadTimer);
        setTimeout(function(){
          $('#preloader').addClass('loaded');
        }, 600);
        setTimeout(function(){
          $('body').addClass('loaded');
        }, 1350);
      }
      document.getElementById("countdown").innerHTML = timeleft;
      timeleft -= 1;
    }, 500);
  },
  rebuildAllEvents: function(){
    this.initLoad();
  },
};

global.siteGlobal = siteGlobal;


(function () {
  siteGlobal.initReady();
  
  $(window).on('load', function() {
    siteGlobal.initLoad();
  });

  $(window).on('scroll', function(){
    siteGlobal.initScroll();
  })
}());